<template>
  <div class="page-container">
    <progress-step :step="page.step"></progress-step>
    <div class="page-content" class1="option">
      <v-card flat>
        <v-card-text>
          <div class="bold-f-s">{{text}}</div>
          <v-icon v-if="show" color="primary" @click="showModal = true">info</v-icon>
        </v-card-text>
        <v-card-text>
          <select-scroll ref="month" :listData="item" @input="submit" v-model="approvedAmount"></select-scroll>
        </v-card-text>
      </v-card>
    </div>
    <div class="page-action">
      <v-btn
        large
        block
        color="primary"
        @click="next">NEXT
      </v-btn>
    </div>
    <v-overlay
      absolute
      :value="overlay"
    >
      <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
    </v-overlay>
    <v-dialog max-width="500px" v-model="showModal">
      <v-card flat>
        <v-card-text>
          *You can request your Initial Advance in the first sixty (60) days from the initial Disbursement Date and may not exceed
          50% of your Amount Financed. If your account remains in good standing for sixty (60) days from the initial Disbursement Date,
          you may thereafter request Subsequent Advances up to 75% of the Amount Financed. If your account remains in good standing for
          one hundred and twenty days (120) days from the initial Disbursement Date, you may thereafter request Subsequent Advances up
          to 100% of the Amount Financed for the remainder of the Advance Period. See details on your Multiple-Advance Installment Loan Agreement.
        </v-card-text>
        <v-card-actions>
          <v-btn
            x-large
            block
            color="primary"
            class="width-150"
            @click="showModal=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { OriginationApi } from '../../api/application'
import { Tool, DataType } from '../../js/core'
import ProgressStep from '../../components/progress/ProgressStep'
import SelectScroll from '../../components/ailurus/SelectScroll'

const tool = new Tool()

export default {
  components: { SelectScroll, ProgressStep },
  data () {
    return {
      page: DataType.PAGES.LOAN_AMOUNT,
      approvedAmount: 0,
      loanAmount: 0,
      text: 'Select your loan amount',
      reasonCode: 0,
      notes: '',
      showModal: false,
      show: false,
      overlay: true,
      program: {
        minimumLoanAmount: '200',
        id: ''
      },
      item: []
    }
  },
  methods: {
    next () {
      const _this = this
      _this.overlay = true
      OriginationApi.updateOrigination({
        feature: 'payment-option',
        data: {
          loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          approvedAmount: _this.approvedAmount
        }
      }, function () {
        _this.overlay = false
        const stepMessage = {
          currect: DataType.PAGES.LOAN_AMOUNT,
          next: DataType.PAGES.TRANSACTION
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.LOAN_AMOUNT.step,
          DataType.PAGES.TRANSACTION.step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        const portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
        if (portfolio.individualTransactionMode && portfolio.individualTransactionMode.toString() === '1') {
          _this.$router.push(DataType.PAGES.TRANSACTION.address)
        } else {
          OriginationApi.pageControl(null, function (pageControl) {
            if (
              pageControl !== null &&
              pageControl !== undefined &&
              pageControl.indexOf(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)) > 0
            ) {
              _this.$router.push(DataType.PAGES.MILITARY.address)
              _this.page.next = 'MILITARY'
            } else {
              _this.$router.push(DataType.PAGES.PREVIEW.address)
            }
          })
        }
      })
    },
    toCurrency (money) {
      return tool.formatCurrencyInt(money)
    },
    submit (data) {
      if (data) {
        this.approvedAmount = data.toString().replace(/[^0-9]/ig, '')
      }
    }
  },
  async created () {
    const _this = this
    _this.$store.commit('setCurrentPage', _this.page)
    if (parseInt(localStorage.getItem(DataType.COOKIE_KEY.LOAN_TYPE)) === DataType.LoanTypeEnum.MULTIPLE_ADVANCE_LOAN.value) {
      _this.show = true
      _this.text = 'Select your Initial Advance loan amount'
    }
    await OriginationApi.getLoanById({
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      feature: 'PAYMENT_OPTION'
    }, function (result) {
      if (parseInt(localStorage.getItem(DataType.COOKIE_KEY.LOAN_TYPE)) === DataType.LoanTypeEnum.MULTIPLE_ADVANCE_LOAN.value) {
        _this.loanAmount = result.loanAmount / 2
        _this.program.minimumLoanAmount = 200
        _this.approvedAmount = result.loanAmount / 2
        _this.overlay = false
      } else {
        _this.loanAmount = result.loanAmount
        _this.program.minimumLoanAmount = 200
        _this.approvedAmount = result.loanAmount
        _this.overlay = false
      }
    })
    _this.overlay = false
    let size = _this.loanAmount
    // size = 1250
    while (size >= 200) {
      _this.item.push(_this.toCurrency(size))
      size = size - 25
    }
  }
}
</script>
<style lang="css">
  .option input {
    text-align: center;
  }

  .option .v-input input {
    max-height: unset;
  }
</style>
